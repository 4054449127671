export interface RedmineIssueResponse {
  issue: Issue;
}

export interface RedmineIssuesResponse {
  issues: Issue[];
  total_count: number;
  offset: number;
  limit: number;
}

export interface CustomRedemineIssuesResponse {
  data: {
    issue: Omit<Issue, 'custom_fields'>;
    custom_fields: CustomField[];
  }[];
}

export interface Issue {
  id: number;
  project: FieldSchema;
  tracker: FieldSchema;
  status: FieldSchema;
  priority: FieldSchema;
  author: FieldSchema;
  subject: string;
  description: string | null;
  start_date: string | null;
  due_date: string | null;
  done_ratio: number;
  is_private: boolean;
  estimated_hours: string | null;
  total_estimated_hours: string | null;
  custom_fields: CustomField[];
  created_on: string;
  updated_on: string;
  closed_on: string;
  journals?: Journal[];
  relations?: Relation[];
  issue?: Issue;
}

export interface Relation {
  id: number;
  issue_id: number;
  issue_to_id: number;
  relation_type: string;
  delay?: any;
}

export interface FieldSchema {
  id: number;
  name: string;
}

export type CustomFieldValue = any[] | string | number | null;

export interface CustomField {
  id: number;
  name: string;
  value: any[] | string | number | null;
  multiple?: boolean;
}

export interface Journal {
  id: number;
  user: AssignedTo;
  notes: string;
  created_on: Date;
  private_notes: boolean;
  details: Detail[];
}

export interface AssignedTo {
  id: number;
  name: string;
}

export interface Detail {
  property: Property;
  name: string;
  old_value: null | string;
  new_value: string;
}

export enum Property {
  Attr = 'attr',
  CF = 'cf'
}

export type CustomFieldHash = Record<
  string,
  {
    name: string;
    type: string;
    possible_values: string[];
    multiple: boolean;
  }
>;
export interface GetCustomFieldsResponse {
  data: CustomFieldHash;
}
export type GetGridSchemasResponse = {
  data: {
    [key: string]: GridSchema[];
  };
};

export type GridSchema = {
  position: number;
  grid_column_id: number;
  type: string;
  possible_values: string[];
  multiple: boolean;
  name: string;
  displayed_label: string;
};

export function DefaultRedmineIssueResponse(): RedmineIssuesResponse {
  return {
    issues: [
      {
        id: 0,
        project: {
          id: 0,
          name: ''
        },
        tracker: {
          id: 0,
          name: ''
        },
        status: {
          id: 0,
          name: ''
        },
        priority: {
          id: 0,
          name: ''
        },
        author: {
          id: 0,
          name: ''
        },
        subject: '',
        description: '',
        start_date: '',
        due_date: '',
        done_ratio: 0,
        is_private: false,
        estimated_hours: null,
        total_estimated_hours: null,
        created_on: '',
        updated_on: '',
        closed_on: '',
        custom_fields: []
      }
    ],
    total_count: 0,
    offset: 0,
    limit: 0
  };
}
