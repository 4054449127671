import { Card } from '@/components/atoms/Card';
import { PageWrapper } from '@/components/atoms/PageWrapper';
import { PrintStyle } from '@/components/atoms/PrintStyle';
import Spinner from '@/components/atoms/Spinner';
import { TableGrid } from '@/components/atoms/TableGrid';
import { WhiteCard } from '@/components/atoms/WhiteCard';
import { XML039Schema } from '@/constants/redmineSchemas/xml039';
import { getGridSchemas, getIssuesRedmine2 } from '@/services/utils.services';
import {
  DefaultRedmineIssueResponse,
  RedmineIssuesResponse
} from '@/types/redmine';
import { tablefyGridSchema } from '@/utils/tablesUtils';
import { extractYear, formatDate } from '@/utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { twMerge } from 'tailwind-merge';

export const Stacked = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col justify-between gap-2">{children}</div>;
};

export type verticalAlignment = 'top' | 'center' | 'bottom';

export const Spread = ({
  alignVertical,
  children
}: {
  alignVertical?: verticalAlignment;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={twMerge(
        'flex justify-between gap-x-1',
        alignVertical ? getAlignVertical(alignVertical) : ''
      )}
    >
      {children}
    </div>
  );
};

export const Adjacent = ({
  alignVertical = 'center',
  children
}: {
  alignVertical?: verticalAlignment;
  children: React.ReactNode;
}) => {
  return (
    <div className={twMerge('flex gap-x-2', getAlignVertical(alignVertical))}>
      {children}
    </div>
  );
};

export const getAlignVertical = (alignVertical?: verticalAlignment) => {
  if (alignVertical === 'top') {
    return 'items-start';
  } else if (alignVertical === 'center') {
    return 'items-center';
  } else {
    return 'items-end';
  }
};

export const Textsm = ({
  color,
  children
}: {
  color: string;
  children: React.ReactNode;
}) => {
  return <span className={twMerge('text-sm', color)}>{children}</span>;
};

export const TextBold = ({
  color,
  children
}: {
  color: string;
  children: React.ReactNode;
}) => {
  return (
    <span className={twMerge('text-lg font-bold', color)}>{children}</span>
  );
};

const Textlg = ({
  color,
  children
}: {
  color: string;
  children: React.ReactNode;
}) => {
  return <span className={twMerge('text-lg', color)}>{children}</span>;
};

export const StackedPrimary = ({
  label,
  value
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <Stacked>
      <Textsm color="text-white/80">{label}</Textsm>
      <TextBold color="text-white">{value}</TextBold>
    </Stacked>
  );
};

export const StackedWhite = ({
  label,
  value
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <Stacked>
      <Textsm color="text-primary">{label}</Textsm>
      <TextBold color="text-primary">{value}</TextBold>
    </Stacked>
  );
};

export const StackedText = ({
  label,
  value
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <Stacked>
      <Textlg color="text-gray2/70">{label}</Textlg>
      <Textlg color="text-dark">{value}</Textlg>
    </Stacked>
  );
};

export const TextLongComponent = ({
  title,
  content
}: {
  title: string;
  content: string;
}) => {
  return (
    <WhiteCard>
      <div className="min-h-[100px] flex gap-2">
        <div className="w-1/4 p-2">
          <TextBold color="text-primary">{title}</TextBold>
        </div>
        <div className="w-3/4 p-2">
          <Textlg color="text-dark">{content}</Textlg>
        </div>
      </div>
    </WhiteCard>
  );
};

export const FooterCard = ({
  title,
  content
}: {
  title: string;
  content: string;
}) => {
  return (
    <WhiteCard>
      <Stacked>
        <Textlg color="text-gray2/70">{title}</Textlg>
        <TextBold color="text-dark">{content}</TextBold>
      </Stacked>
    </WhiteCard>
  );
};

export const DoubleFooterCards = ({
  title1,
  content1,
  title2,
  content2
}: {
  title1: string;
  content1: string;
  title2: string;
  content2: string;
}) => {
  return (
    <div className="w-2/3 flex gap-2">
      <FooterCard title={title1} content={content1} />
      <FooterCard title={title2} content={content2} />
    </div>
  );
};

export const Xml039Report = () => {
  const { code = '' } = useParams();

  const projectId = 16;
  const cfIds = XML039Schema.getAllCfIds();
  const limit = 1;
  const page = 1;
  const filters = [
    {
      cf_id: XML039Schema.XML039CfIds.Codigo,
      value: code
    }
  ];

  const { data = DefaultRedmineIssueResponse(), isLoading } = useQuery({
    queryKey: ['xml039-report-data', code],
    queryFn: async () =>
      (
        await getIssuesRedmine2({
          project_id: projectId,
          cf_ids: cfIds,
          limit,
          offset: page,
          filters
        })
      ).data,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  });

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const issueId = data.issues[0].issue?.id;

  const getCFValue = (cfId: XML039Schema.XML039CfIds) => {
    return XML039Schema.getCFValue(data, cfId);
  };

  const getCFGrid = (cfId: XML039Schema.XML039CfIds) => {
    return XML039Schema.getCFGrid(data, cfId);
  };

  return (
    <PageWrapper>
      <div
        ref={componentRef}
        className="w-full h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full"
      >
        <div className="flex flex-col w-full flex-wrap gap-2 rounded-lg px-4 py-3 bg-background">
          <PrintStyle />

          <div className="text-2xl print:text-lg print:pl-3 print:p-0 text-primary font-bold border-b border-primary print:border-none print:rounded-lg print:items-center pb-4 mb-2 w-full flex justify-between print:bg-[#EDF0FA]">
            <div className="flex print:flex-col print:gap-0 gap-2 print:items-start items-center">
              <span className="print:text-slate-800">
                NFSM - Notificação de Falha do Sistema de Medição
              </span>
              <span className="print:hidden text-slate-600">-</span>
              <span className="text-slate-600 text-lg print:text-2xl print:flex print:text-primary">
                {formatDate(
                  getCFValue(XML039Schema.XML039CfIds.Data_do_Relatorio)
                )}
              </span>
            </div>

            <Adjacent>
              <button
                onClick={() => {
                  window.open(
                    `${import.meta.env.VITE_REDMINE_URL}` +
                      (issueId ? `/issues/${issueId}` : ''),
                    '_blank'
                  );
                }}
                className="text-xs px-4 h-6 rounded-full border
                  no-print disabled:cursor-not-allowed disabled:text-gray"
                disabled={isLoading}
              >
                Acessar Boletim
              </button>

              <button
                onClick={handlePrint}
                className="text-xs px-4 h-6 rounded-full border
                  no-print disabled:cursor-not-allowed disabled:text-gray"
                disabled={isLoading}
              >
                Imprimir
              </button>
            </Adjacent>
            <img
              src="/origem.png"
              alt="Logo Origem"
              className="w-[203px] only-print resize-none max-w-none -ml-5"
            />
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <Spinner />
            </div>
          ) : (
            <Xml039ReportContent
              getCFValue={getCFValue}
              getCFGrid={getCFGrid}
            />
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

function Xml039ReportContent({
  getCFValue,
  getCFGrid
}: {
  readonly getCFValue: (cfId: XML039Schema.XML039CfIds) => string;
  readonly getCFGrid: (cfId: XML039Schema.XML039CfIds) => any;
}) {
  const { data: gridSchemas = { data: {} }, isLoading: isLoadingGridSchemas } =
    useQuery({
      queryFn: async () =>
        await getGridSchemas([
          XML039Schema.XML039CfIds.Grid_Correcao_de_Volume,
          XML039Schema.XML039CfIds.Grid_Correcao_de_BSW,
          XML039Schema.XML039CfIds.Grid_Correcao_de_Calibracao
        ]),
      queryKey: ['xml039_grids_schemas']
    });

  return (
    <Stacked>
      <div className="grid grid-cols-7 gap-2 h-min-[100px]">
        <div className="h-full col-span-5">
          <Card color="bg-primary">
            <Spread>
              {[
                {
                  label: 'Operador',
                  value: 'Origem Energia Alagoas S.A.'
                },
                {
                  label: 'Data do Relatório',
                  value: formatDate(
                    getCFValue(XML039Schema.XML039CfIds.Data_do_Relatorio)
                  )
                },
                {
                  label: 'Ano',
                  value: extractYear(
                    getCFValue(XML039Schema.XML039CfIds.Data_do_Relatorio)
                  )
                },
                {
                  label: 'Código',
                  value: getCFValue(XML039Schema.XML039CfIds.Codigo)
                },
                {
                  label: 'Campo',
                  value: getCFValue(XML039Schema.XML039CfIds.Campo)
                }
              ].map(item => (
                <StackedPrimary
                  key={item.label}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </Spread>
          </Card>
        </div>
        <div className="col-span-2">
          <Card color="bg-primary">
            <StackedPrimary label="FO-COR-GER-CPROD-026" value="Revisão: 1" />
          </Card>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-2">
        <div className="col-span-5">
          <WhiteCard>
            <Spread>
              {[
                {
                  label: 'Tipo de Notificação:',
                  value: getCFValue(
                    XML039Schema.XML039CfIds.Tipo_de_Notificacao
                  )
                },
                {
                  label: 'Notificação Relacionada:',
                  value: 'Empty'
                },
                {
                  label: 'Instalação:',
                  value: getCFValue(XML039Schema.XML039CfIds.Instalacao)
                },
                {
                  label: 'Código da Instalação:',
                  value: getCFValue(
                    XML039Schema.XML039CfIds.Codigo_da_Instalacao
                  )
                }
              ].map(item => (
                <StackedWhite
                  key={item.label}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </Spread>
          </WhiteCard>
        </div>
        <div className="col-span-2">
          <WhiteCard>
            <Stacked>
              <Textsm color="text-primary">Localização</Textsm>
              <Spread>
                <Adjacent alignVertical="center">
                  <TextBold color="text-primary">Bacia:</TextBold>
                  <Textsm color="text-primary">
                    {getCFValue(XML039Schema.XML039CfIds.Bacia)}
                  </Textsm>
                </Adjacent>
                <Adjacent alignVertical="center">
                  <TextBold color="text-primary">Estado:</TextBold>
                  <Textsm color="text-primary">
                    {getCFValue(XML039Schema.XML039CfIds.Estado)}
                  </Textsm>
                </Adjacent>
              </Spread>
            </Stacked>
          </WhiteCard>
        </div>
      </div>

      <WhiteCard>
        <Stacked>
          <div className="flex justify-between gap-4 border-b border-slate-200">
            {[
              {
                label: 'Tag do ponto de medição',
                value: getCFValue(
                  XML039Schema.XML039CfIds.Tag_do_Ponto_de_Medicao
                )
              },
              {
                label: 'Tipo de notificação',
                value: getCFValue(XML039Schema.XML039CfIds.Tipo_de_Notificacao)
              },
              {
                label: 'Equipamento',
                value: getCFValue(XML039Schema.XML039CfIds.Equipamento)
              },
              {
                label: 'Número de série',
                value: getCFValue(XML039Schema.XML039CfIds.Numero_de_Serie)
              },
              {
                label: 'Tipo de fluido',
                value: getCFValue(XML039Schema.XML039CfIds.Tipo_de_Fluido)
              }
            ].map(item => (
              <StackedText
                key={item.label}
                label={item.label}
                value={item.value}
              />
            ))}
          </div>
          <div className="flex justify-between pt-4">
            {[
              {
                label: 'Data de ocorrência',
                value: formatDate(
                  getCFValue(XML039Schema.XML039CfIds.Data_de_Ocorrencia)
                )
              },
              {
                label: 'Data de retorno à normalidade',
                value: formatDate(
                  getCFValue(
                    XML039Schema.XML039CfIds.Data_de_Retorno_a_Normalidade
                  )
                )
              },
              {
                label: 'Data de detecção',
                value: formatDate(
                  getCFValue(XML039Schema.XML039CfIds.Data_de_Deteccao)
                )
              },
              {
                label: 'Previsão de Retorno à Normalidade (dias)',
                value: 'Texto texto texto'
              }
            ].map(item => (
              <StackedText
                key={item.label}
                label={item.label}
                value={item.value}
              />
            ))}
          </div>
        </Stacked>
      </WhiteCard>

      <DoubleFooterCards
        title1="Responsável pela detecção"
        content1={getCFValue(
          XML039Schema.XML039CfIds.Responsavel_pela_Deteccao
        )}
        title2="Tipo de Falha"
        content2={getCFValue(XML039Schema.XML039CfIds.Tipo_de_Falha)}
      />

      {[
        {
          title: 'Descrição do evento',
          content: getCFValue(XML039Schema.XML039CfIds.Descricao_do_Evento)
        },
        {
          title: 'Ações tomadas',
          content: getCFValue(XML039Schema.XML039CfIds.Acoes_Tomadas)
        },
        {
          title: 'Metodologia de estimativa',
          content: getCFValue(
            XML039Schema.XML039CfIds.Metodologia_de_Estimativa
          )
        }
      ].map(item => (
        <TextLongComponent
          key={item.title}
          title={item.title}
          content={item.content}
        />
      ))}

      {isLoadingGridSchemas ? (
        <div className="flex justify-center items-center h-64">
          <Spinner />
        </div>
      ) : (
        [
          {
            title: 'Correção de Volume',
            data: getCFGrid(XML039Schema.XML039CfIds.Grid_Correcao_de_Volume),
            columns: tablefyGridSchema(
              gridSchemas,
              XML039Schema.XML039CfIds.Grid_Correcao_de_Volume,
              'text-white'
            )
          },
          {
            title: 'Correção de BSW',
            data: getCFGrid(XML039Schema.XML039CfIds.Grid_Correcao_de_BSW),
            columns: tablefyGridSchema(
              gridSchemas,
              XML039Schema.XML039CfIds.Grid_Correcao_de_BSW,
              'text-white'
            )
          },
          {
            title: 'Correção de Calibração',
            data: getCFGrid(
              XML039Schema.XML039CfIds.Grid_Correcao_de_Calibracao
            ),
            columns: tablefyGridSchema(
              gridSchemas,
              XML039Schema.XML039CfIds.Grid_Correcao_de_Calibracao,
              'text-white'
            )
          }
        ].map(item => (
          <div key={item.title} className="m-2">
            <Stacked>
              <TextBold color="text-primary">{item.title}</TextBold>
              <TableGrid
                headerClassName="bg-primary"
                key={item.title}
                data={item.data}
                cols={item.columns}
              />
            </Stacked>
          </div>
        ))
      )}

      <DoubleFooterCards
        title1="Elaborador"
        content1={getCFValue(XML039Schema.XML039CfIds.Elaborador)}
        title2="Aprovador"
        content2={getCFValue(XML039Schema.XML039CfIds.Aprovador)}
      />
    </Stacked>
  );
}

const getMockXml039Data = (): RedmineIssuesResponse => {
  return {
    issues: [
      {
        id: 1,
        project: { id: 1, name: 'Projeto Teste' },
        tracker: { id: 1, name: 'Notificação XML039' },
        status: { id: 1, name: 'Novo' },
        priority: { id: 1, name: 'Normal' },
        author: { id: 1, name: 'Usuário Teste' },
        subject: 'Notificação de Movimentação de Sonda',
        description: 'Descrição da notificação de movimentação de sonda',
        start_date: '2023-01-01',
        due_date: '2023-01-31',
        done_ratio: 0,
        is_private: false,
        estimated_hours: null,
        total_estimated_hours: null,
        created_on: '2023-01-01T10:00:00Z',
        updated_on: '2023-01-01T10:00:00Z',
        closed_on: '',
        custom_fields: [
          {
            id: XML039Schema.XML039CfIds.Data_do_Relatorio,
            name: 'Data do Relatório',
            value: '2023-01-15'
          },
          {
            id: XML039Schema.XML039CfIds.Codigo,
            name: 'Código',
            value: 'XML039-001/2023'
          },
          {
            id: XML039Schema.XML039CfIds.Campo,
            name: 'Campo',
            value: 'Campo Teste'
          },
          {
            id: XML039Schema.XML039CfIds.Tipo_de_Notificacao,
            name: 'Tipo de Notificação',
            value: 'Movimentação de Sonda'
          },
          {
            id: XML039Schema.XML039CfIds.Instalacao,
            name: 'Instalação',
            value: 'Instalação Teste'
          },
          {
            id: XML039Schema.XML039CfIds.Codigo_da_Instalacao,
            name: 'Código da Instalação',
            value: 'INST-001'
          },
          {
            id: XML039Schema.XML039CfIds.Bacia,
            name: 'Localização',
            value: 'Bacia Teste'
          },
          {
            id: XML039Schema.XML039CfIds.Estado,
            name: 'Estado',
            value: 'Bahia'
          },
          {
            id: XML039Schema.XML039CfIds.Tag_do_Ponto_de_Medicao,
            name: 'Tag do Ponto de Medição',
            value: 'TAG-001'
          },
          {
            id: XML039Schema.XML039CfIds.Tipo_de_Medicao,
            name: 'Tipo de Medição',
            value: 'Tipo Teste'
          },
          {
            id: XML039Schema.XML039CfIds.Equipamento,
            name: 'Equipamento',
            value: 'Sonda PR4'
          },
          {
            id: XML039Schema.XML039CfIds.Tipo_de_Fluido,
            name: 'Tipo de Fluido',
            value: 'Óleo'
          },
          {
            id: XML039Schema.XML039CfIds.Data_de_Ocorrencia,
            name: 'Data de Ocorrência',
            value: '2023-01-10'
          },
          {
            id: XML039Schema.XML039CfIds.Data_de_Retorno_a_Normalidade,
            name: 'Data de Retorno à Normalidade',
            value: '2023-01-12'
          },
          {
            id: XML039Schema.XML039CfIds.Data_de_Deteccao,
            name: 'Data de Detecção',
            value: '2023-01-10'
          },
          {
            id: XML039Schema.XML039CfIds.Responsavel_pela_Deteccao,
            name: 'Responsável pela Detecção',
            value: 'João Silva'
          },
          {
            id: XML039Schema.XML039CfIds.Tipo_de_Falha,
            name: 'Tipo de Falha',
            value: 'Falha Mecânica'
          },
          {
            id: XML039Schema.XML039CfIds.Descricao_do_Evento,
            name: 'Descrição do Evento',
            value: 'Descrição detalhada do evento de movimentação da sonda'
          },
          {
            id: XML039Schema.XML039CfIds.Acoes_Tomadas,
            name: 'Ações Tomadas',
            value: 'Ações corretivas implementadas'
          },
          {
            id: XML039Schema.XML039CfIds.Metodologia_de_Estimativa,
            name: 'Metodologia de Estimativa',
            value: 'Metodologia padrão'
          },
          {
            id: XML039Schema.XML039CfIds.Elaborador,
            name: 'Elaborador',
            value: 'Maria Oliveira'
          },
          {
            id: XML039Schema.XML039CfIds.Aprovador,
            name: 'Aprovador',
            value: 'João Silva'
          },
          {
            id: XML039Schema.XML039CfIds.Numero_de_Serie,
            name: 'Número de Série',
            value: '1234567890'
          }
        ]
      }
    ],
    total_count: 1,
    offset: 0,
    limit: 25
  };
};
