import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  getOneOilwellByApplicationDate,
  updateOilwell
} from '../../../services/oilwells.services';

import { useAccount } from '@azure/msal-react';
import {
  addHours,
  format,
  isBefore,
  isToday,
  parse,
  parseISO,
  startOfMonth,
  subMonths
} from 'date-fns';
import { FaCircleInfo } from 'react-icons/fa6';
import Datepicker from 'react-tailwindcss-datepicker';
import { queryClient } from '../../../App';
import Button from '../../../components/atoms/Button';
import EmptyMessage from '../../../components/atoms/EmptyMessage';
import Input from '../../../components/atoms/Input';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import {
  injectionTypes,
  targetInstallationCode,
  waterBranch
} from '../../../constants/filters/OilWellsTable';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { mapperOptions } from '../../../utils/mdm';
import {
  formatDateTimeISO,
  formatDateWithoutTimezone
} from '../../../utils/utils';

type UpdateOilWellForm = {
  name: string;
  type: string;
  oilFieldId: string;
  statusId: string;
  nameANP: string;
  codeANP: string;
  satelliteId: string;
  locationId: string;
  conjugateLineId: string;
  fluid: string;
  elevationMethod: string;
  injectionType: string;
  waterBranch: string;
  targetInstallationCode: string;
  updateNext?: boolean;
  interventionExpense?: string;
  oilWellHealth?: number;
  latitude?: number;
  longitude?: number;
  averageDepth?: number;
  verticalDepth?: number;
  sedimentaryBasin?: string;
  environment?: string;
  operationalLife?: string;
};

type Params = {
  id: string;
};

const UpdateOilWell: React.FC = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });
  const [currentHour, setCurrentHour] = useState<string>(
    isToday(parseISO(currentDate.startDate))
      ? format(new Date(), 'HH:mm')
      : '23:59'
  );
  const [lastApplicationDate, setLastApplicationDate] = useState<string>('');
  const [history, setHistory] = useState([]);
  const { id } = useParams<Params>();
  const { status, satellites, fields, constants, location, conjugateLine } =
    useMdmUtils({
      status: true,
      satellites: true,
      fields: true,
      constants: true,
      location: true,
      conjugateLine: true
    });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { errors, isValid }
  } = useForm<UpdateOilWellForm>();

  const mapValues = (data: any) => {
    const res: { [key: string]: string } = {};
    Object.keys(data).forEach(it => {
      const dataValue = data[it];
      if (it == 'statusId')
        res['Status'] = status.find((st: any) => st.id == dataValue)?.anp;
      else if (it == 'oilFieldId')
        res['Campo'] = fields.find((st: any) => st.id == dataValue)?.name;
      else if (it == 'satelliteId')
        res['Satélite'] = satellites.find(
          (st: any) => st.id == dataValue
        )?.name;
      else if (it == 'locationId')
        res['Locação'] = location.find((st: any) => st.id == dataValue)?.name;
      else if (it == 'conjugateLineId')
        res['Linha Conjugada'] = conjugateLine.find(
          (st: any) => st.id == dataValue
        )?.name;
      else if (it == 'name') res['Nome'] = dataValue;
      else if (it == 'type') res['Tipo'] = dataValue;
      else if (it == 'nameANP') res['Nome ANP'] = dataValue;
      else if (it == 'codeANP') res['Código'] = dataValue;
      else if (it == 'fluid') res['Flúido'] = dataValue;
      else if (it == 'elevationMethod') res['Método de elevação'] = dataValue;
      else if (it == 'injectionType') res['Tipo de injeção'] = dataValue;
      else if (it == 'waterBranch') res['Ramal de Água'] = dataValue;
      else if (it == 'targetInstallationCode')
        res['Instalação de Destino'] = dataValue;
      else if (it == 'interventionExpense')
        res['Despesa de Intervenção'] = dataValue;
      else res[it] = dataValue;
    });

    return res;
  };

  const getOilWell = async () => {
    if (!id) return toast.error('Selecione um poço para atualizar');
    try {
      const { data, applicationDate, history } =
        await getOneOilwellByApplicationDate(id, {
          date: `${currentDate.startDate}T${currentHour}`
        });
      reset({
        name: data.name,
        type: data.type,
        oilFieldId: data.oilFieldId,
        statusId: data.statusId,
        nameANP: data.nameANP,
        codeANP: data.codeANP,
        satelliteId: data.satelliteId,
        fluid: data.fluid,
        elevationMethod: data.elevationMethod,
        locationId: data.locationId,
        conjugateLineId: data.conjugateLineId,
        injectionType: data.injectionType,
        waterBranch: data.waterBranch,
        targetInstallationCode: data.targetInstallationCode,
        interventionExpense: data.interventionExpense,
        operationalLife: data.operationalLife
          ? format(new Date(data.operationalLife), 'yyyy-MM-dd')
          : '',
        latitude: data.latitude,
        longitude: data.longitude,
        averageDepth: data.averageDepth,
        verticalDepth: data.verticalDepth,
        environment: data.environment,
        sedimentaryBasin: data.sedimentaryBasin
      });

      setHistory(
        history.map((item: any) => ({
          ...item,
          oldData: mapValues(item.oldData),
          newData: mapValues(item.newData)
        }))
      );
      setLastApplicationDate(applicationDate);
    } catch (e) {
      toast.error('Erro ao buscar poço');
    }
  };

  const user = useAccount();

  useEffect(() => {
    if (currentHour.match(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/)) getOilWell();
  }, [currentDate.startDate, currentHour]);

  const onSubmit = async (data: UpdateOilWellForm) => {
    if (!id) return toast.error('Selecione um poço para atualizar');

    const updatedData = Object.keys(data).reduce((acc: any, key: any) => {
      const typedKey = key as keyof UpdateOilWellForm;
      const value = data[typedKey];

      if (
        ['latitude', 'longitude', 'averageDepth', 'verticalDepth'].includes(
          typedKey
        )
      ) {
        acc[typedKey] = value === '' ? null : parseFloat(value as string);
      } else if (typedKey === 'operationalLife') {
        acc[typedKey] =
          value === '' ? null : addHours(new Date(value as string), 3);
      } else {
        acc[typedKey] = value === '' ? null : value;
      }

      return acc;
    }, {} as UpdateOilWellForm);

    if (
      isToday(parseISO(currentDate.startDate))
        ? isBefore(new Date(), parse(currentHour, 'HH:mm', new Date()))
        : false
    )
      return toast.error(
        'Não é possível selecionar uma hora futura para a aplicação'
      );

    try {
      await updateOilwell(
        id,
        updatedData,
        `${currentDate.startDate}T${currentHour}`
      );

      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'oilwells-adm'
      });

      toast.success('Poço atualizado com sucesso');
    } catch (e) {
      toast.error('Erro ao atualizar poço');
    }
  };

  const today = new Date();

  return (
    <MdmLayout title={'Atualizar Poço'}>
      <div className="flex flex-col flex-end w-3/4 mb-2">
        <label className="font-medium text-sm text-primary">
          Data de Aplicação
        </label>
        <Datepicker
          value={currentDate}
          asSingle={true}
          useRange={false}
          onChange={(val: any) => {
            setCurrentDate(val);
          }}
          i18n="pt-br"
          placeholder="Selecione uma data"
          inputClassName={
            'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-md tracking-wide text-sm focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20 mb-2'
          }
          classNames={{
            toggleButton() {
              return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
            }
          }}
          maxDate={new Date()}
          minDate={
            user?.idTokenClaims?.roles?.includes('Portal.SuperAdmin')
              ? null
              : today.getDate() <= 15
                ? startOfMonth(subMonths(today, 1))
                : startOfMonth(today)
          }
        />

        <Input
          label="Hora de Aplicação"
          required
          placeholder="Hora de Aplicação"
          value={currentHour}
          onChange={e => setCurrentHour(e.target.value)}
          type="time"
          max={
            isToday(parseISO(currentDate.startDate))
              ? format(new Date(), 'HH:mm')
              : undefined
          }
          isError={
            isToday(parseISO(currentDate.startDate))
              ? isBefore(new Date(), parse(currentHour, 'HH:mm', new Date()))
              : false
          }
        />

        {lastApplicationDate && (
          <div className="w-full flex items-center text-xs font-normal text-[#666] mt-2">
            <FaCircleInfo className="mr-2" size={12} />
            <strong className="font-bold mr-1">Data do último registro:</strong>
            {lastApplicationDate !== 'present'
              ? format(
                  new Date(lastApplicationDate.replace('Z', '')),
                  'dd/MM/yyyy HH:mm'
                )
              : 'Sem registros'}
          </div>
        )}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Nome"
          required
          placeholder="Nome"
          disabled
          {...register('name')}
        />

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Tipo"
            placeholder="Tipo"
            control={control}
            required
            name={'type'}
            options={mapperOptions(constants?.OilWellType)}
          />

          <SelectInput
            label="Campo"
            placeholder="Campo"
            control={control}
            name={'oilFieldId'}
            required
            options={fields?.map((field: any) => ({
              value: field.id,
              label: field.name
            }))}
          />
        </div>

        <SelectInput
          label="Status ANP"
          placeholder="Status"
          control={control}
          required
          name={'statusId'}
          options={status?.map((status: any) => ({
            value: status.id,
            label: status.anp
          }))}
        />

        <div className="flex gap-4 w-full">
          <Input
            label="Nome ANP"
            placeholder="Nome ANP"
            required
            {...register('nameANP', { required: true })}
          />

          <Input
            label="Código ANP"
            required
            placeholder="Código ANP"
            {...register('codeANP', { required: true })}
          />
        </div>

        <SelectInput
          label="Satelite"
          placeholder="Satelite"
          control={control}
          name={'satelliteId'}
          options={satellites?.map((satellite: any) => ({
            value: satellite.id,
            label: satellite.name
          }))}
        />

        <Input
          label="Vida útil do poço"
          placeholder="Vida útil do poço"
          type="date"
          {...register('operationalLife', { required: false })}
        />

        <div className="flex gap-4 w-full">
          <Input
            label="Latitude"
            placeholder="Latitude"
            type="number"
            step={0.1}
            {...register('latitude', { required: false })}
          />

          <Input
            label="Longitude"
            placeholder="Longitude"
            type="number"
            step={0.1}
            {...register('longitude', { required: false })}
          />
        </div>

        <div className="flex gap-4 w-full">
          <Input
            label="Profundidade MD"
            placeholder="Profundidade MD"
            type="number"
            step={0.1}
            {...register('averageDepth', { required: false })}
          />

          <Input
            label="Profundidade Vertical"
            placeholder="Profundidade Vertical"
            type="number"
            step={0.1}
            {...register('verticalDepth', { required: false })}
          />
        </div>

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Bacia Sedimentar"
            placeholder="Bacia Sedimentar"
            control={control}
            name="sedimentaryBasin"
            options={[
              { value: 'Alagoas', label: 'Alagoas' },
              { value: 'Espírito Santo', label: 'Espírito Santo' },
              { value: 'Potiguar', label: 'Potiguar' },
              { value: 'Recôncavo', label: 'Recôncavo' },
              { value: 'Tucano Sul', label: 'Tucano Sul' }
            ]}
          />

          <SelectInput
            label="Ambiente"
            placeholder="Ambiente"
            name="environment"
            control={control}
            options={[
              { value: 'Onshore', label: 'Onshore' },
              { value: 'Offshore', label: 'Offshore' }
            ]}
          />
        </div>

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Locação"
            placeholder="Locação"
            control={control}
            name={'locationId'}
            options={location?.map((location: any) => ({
              value: location.id,
              label: location.name
            }))}
          />

          <SelectInput
            label="Linha Conjugada"
            placeholder="Linha Conjugada"
            control={control}
            name={'conjugateLineId'}
            options={conjugateLine?.map((line: any) => ({
              value: line.id,
              label: line.name
            }))}
          />
        </div>

        <SelectInput
          label="Ramal de Água"
          placeholder="Ramal de Água"
          control={control}
          name={'waterBranch'}
          options={waterBranch}
        />

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Tipo de Injeção"
            placeholder="Tipo de Injeção"
            control={control}
            name={'injectionType'}
            options={
              mapperOptions(constants?.InjectionType).length > 0
                ? mapperOptions(constants?.InjectionType)
                : injectionTypes
            }
          />

          <SelectInput
            label="Instalação de Destino"
            placeholder="Instalação de Destino"
            control={control}
            name={'targetInstallationCode'}
            options={targetInstallationCode}
            disabled={currentDate.startDate?.split('-')[2] !== '01'}
            info="A instalação de destino será sobrescrita para todas as datas a partir do dia selecionado"
          />
        </div>

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Fluído"
            placeholder="Fluído"
            required
            control={control}
            name={'fluid'}
            options={mapperOptions(constants?.Fluids)}
          />

          <SelectInput
            options={mapperOptions(constants?.ElevationMethods)}
            label="Método de elevação"
            placeholder="Método de elevação"
            control={control}
            name="elevationMethod"
          />
        </div>

        <SelectInput
          options={mapperOptions(constants?.InterventionExpense)}
          label="Despesa de Intervenção"
          placeholder="Despesa de Intervenção"
          control={control}
          name="interventionExpense"
        />

        {/* <div className="flex w-full">
          <Checkbox
            {...register("updateNext")}
            label="Atualizar próximas aplicações."
          />
        </div> */}

        <Button className="px-24 h-12 mt-4" type="submit" title="Atualizar" />
      </form>

      {['Portal.SuperAdmin', 'Portal.Admin'].some(it =>
        user?.idTokenClaims?.roles?.includes(it)
      ) ? (
        <div className="flex gap-2 w-full flex-col">
          <h3 className="font-bold mt-2">Histórico</h3>
          {history.length === 0 ? (
            <EmptyMessage
              message="Nenhum dado encontrado"
              className="h-min min-h-0"
            />
          ) : null}
          {history?.map((it: any, idx: number) => (
            <div
              className="rounded-md bg-slate-100 w-full px-3 py-2 flex flex-col "
              key={`${it.createdAt}${idx}`}
            >
              <div className="font-normal text-xs">Usuário: {it.author}</div>

              <section className="flex gap-2 w-full flex-col">
                {Object.keys(it.oldData).map(data => (
                  <div className="" key={`${data}${idx}`}>
                    {data} foi atualizado de{' '}
                    <strong>{it.oldData?.[data]}</strong> para{' '}
                    <strong>{it.newData?.[data]}</strong>
                  </div>
                ))}

                <div className="ml-auto font-normal text-xs">
                  Data de Atualização: {formatDateTimeISO(it.createdAt)}
                </div>
                <div className="ml-auto font-normal text-xs">
                  Data de Aplicação:{' '}
                  {formatDateWithoutTimezone(it.applicationDate)}
                </div>
              </section>
            </div>
          ))}
        </div>
      ) : null}
    </MdmLayout>
  );
};

export default UpdateOilWell;
