import { oilWellType } from '../types/oilwell.type';
import api from './apiMdm';
import apiPortal from './apiPortal';

export const path = '/oil-wells';
export const wellSchematicPath = 'well-schematic';

export const getChromaGasLift = async (date: string, oilFieldId: number) =>
  (await api.get(`chromatography-analysis/oil-field/${oilFieldId}/${date}`))
    .data;

export const getAllOilwells = async (
  skip: number,
  take: number,
  search: string,
  filters: {
    [key: string]: [] | string | any;
  } = {
    pole: [],
    field: [],
    fluid: [],
    elevationMethod: [],
    status: [],
    oilwellType: [],
    name: [],
    location: [],
    conjugate: [],
    isDrilling: [],
    satelite: [],
    statusAnp: []
  }
) => {
  if (filters.isDrilling?.[0] === 'Em perfuração') {
    filters.statusAnp = filters.isDrilling;
  }

  const { data } = await api.get(path, {
    params: {
      field: filters.field,
      fluid: filters.fluid,
      elevationMethod: filters.elevationMethod,
      pole: filters.pole,
      status: filters.status,
      name: filters.name,
      type: filters.oilwellType,
      location: filters.location,
      conjugate: filters.conjugate,
      satellite: filters.satelite,
      'status-anp': filters.statusAnp,
      skip,
      take,
      orderBy: 'asc',
      search
    }
  });
  return data;
};

export const getAllOilwellsList = async (
  skip: number,
  take: number,
  search: string,
  filters: {
    [key: string]: [] | string | any;
  } = {
    name: '',
    field: [],
    anpStatus: [],
    nameANP: [],
    statusWellHandover: []
  }
) => {
  const { data } = await api.get(
    `${wellSchematicPath}/get-all-well-schematics`,
    {
      params: {
        name: filters.name,
        field: filters.field,
        nameANP: filters.nameANP,
        'status-anp': filters.anpStatus,
        statusWellHandover: filters.statusWellHandover,
        skip,
        take,
        orderBy: 'asc',
        search
      }
    }
  );

  return data;
};

export const getWellSchematicsTotals = async (
  search: string = '',
  filters: {
    [key: string]: [] | string | any;
  } = {
    name: '',
    field: [],
    anpStatus: [],
    nameANP: [],
    statusWellHandover: []
  }
) => {
  const response = await api.get(`${wellSchematicPath}/statuscounter`, {
    params: {
      name: filters.name,
      field: filters.field,
      nameANP: filters.nameANP,
      'status-anp': filters.anpStatus,
      statusWellHandover: filters.statusWellHandover,
      search,
      orderBy: 'asc'
    }
  });

  return response.data;
};

export const getSchematicData = async (oilWellName: string) => {
  try {
    const { data } = await api.get(`${wellSchematicPath}/show/${oilWellName}`);
    return data;
  } catch (error) {
    return null;
  }
};

export const getAllOilwellsWithoutFilter = async () => {
  const { data } = await api.get(path, {
    params: {
      orderBy: 'asc'
    }
  });
  return data;
};

export const getProductionReportSummary = async (date: string) => {
  const { data } = await apiPortal.get(`/oilwells`, {
    params: {
      route: 'production_report_summary',
      current_date: date,
      limit: 50
    }
  });
  return data;
};

export const getLastOilwellStatus = async (params: { oilwell: string[] }) => {
  const { data } = await apiPortal.get(`/oilwells`, {
    params: {
      ...params,
      route: 'oilwell_last_status'
    }
  });
  return data;
};

export const getAllChroma = async (
  oilFieldId: string,
  applicationDate: string,
  skip: number,
  take: number
) => {
  const { data } = await api.get(
    `chromatography-analysis/${
      oilFieldId ? `oil-field/${oilFieldId}/${applicationDate}` : ''
    }`,
    {
      params: {
        skip,
        take,
        orderBy: 'desc'
      }
    }
  );
  return data;
};

export const getAllOilFields = async () => {
  const { data } = await api.get(`oil-fields`);
  return data;
};

export const getAllGasMeters = async () => {
  const { data } = await api.get(`gas-meters`);
  return data;
};

export const getAllZones = async (params?: {
  [key: string]: string | number | null;
}) => {
  const { data } = await api.get(`zones`, {
    params
  });
  return data;
};

export const getOneZones = async (id: string) => {
  const { data } = await api.get(`zones/${id}`);
  return data;
};

export const getOneZoneByApplicationDate = async (
  id: string,
  params: { date: string }
) => {
  const { data } = await api.get(`zones/${id}/application`, {
    params
  });
  return data;
};

export const getAllOilwellZoneAllocation = async (params?: {
  [key: string]: string | number;
}) => {
  const { data } = await api.get(`oilwell-zone-allocation`, {
    params
  });
  return data;
};

export const getAllOilwellZoneAllocationByOilwell = async (id: string) => {
  const { data } = await api.get(`oilwell-zone-allocation/oilwell/${id}`);
  return data;
};

export const getAllOilwellZoneAllocationByOilwellAndApplication = async (
  id: string,
  date: string
) => {
  const { data } = await api.get(
    `oilwell-zone-allocation/oilwell/${id}/date/${date}`
  );
  return data;
};

export const getAllMeasuringTank = async () => {
  const { data } = await api.get(`measuring-tanks`);
  return data;
};

export const getConstants = async () => {
  const { data } = await api.get(`constants`);
  return data;
};

export const getTags = async (page = 0, perPage = 500) => {
  const { data } = await api.get(
    `pi-tag?is_active=true&per_page=${perPage}&page=${page}`
  );
  return data;
};

export const getLocations = async () => {
  const { data } = await api.get('locations');
  return data;
};

export const getConjugateLines = async () => {
  const { data } = await api.get('conjugate-lines');
  return data;
};

export const getOneOilwellByApplicationDate = async (
  id: string,
  params: { date: string }
) => {
  const { data } = await api.get(`${path}/${id}/application`, {
    params
  });
  return data;
};

export const getOneTank = async (id: string) => {
  const { data } = await api.get(`measuring-tanks/${id}`);
  return data;
};

export const getOilWellIdbyName = async (name: string) => {
  try {
    const { data } = await api.get(`${path}/oil-well-by-name/${name}`);
    return data;
  } catch (error) {
    return null;
  }
};

export const getOneChroma = async (id: string) => {
  const { data } = await api.get(`chromatography-analysis/${id}`);
  return data;
};

export const getOneOilField = async (id: string) => {
  const { data } = await api.get(`oil-fields/${id}`);
  return data;
};

export const postOilwell = async (oilwell: oilWellType) => {
  const { data } = await api.post(path, oilwell);
  return data;
};

export const postOilField = async (oilField: any) => {
  const { data } = await api.post('oil-fields', oilField);
  return data;
};

export const postChroma = async (chroma: any) => {
  const { data } = await api.post('chromatography-analysis', chroma);
  return data;
};

export const postSatellite = async (satellite: any) => {
  const { data } = await api.post('satellites', satellite);
  return data;
};

export const getOneGasMeter = async (id: string) => {
  const { data } = await api.get(`gas-meters/${id}`);
  return data;
};

export const postGasMeter = async (gasMeter: any) => {
  const { data } = await api.post('gas-meters', gasMeter);
  return data;
};

export const postZone = async (zone: any) => {
  const { data } = await api.post('zones', zone);
  return data;
};

export const patchZone = async (id: string, zone: any, date: string) => {
  const { data } = await api.patch(`zones/${id}/${date}`, zone);
  return data;
};

export const postAllocation = async (alloc: any, params: { date: string }) => {
  const { data } = await api.post('oilwell-zone-allocation', alloc, {
    params
  });
  return data;
};

export const updateAllocation = async (
  id: string,
  alloc: any,
  date: string,
  updateNext: boolean
) => {
  const { data } = await api.patch(
    `oilwell-zone-allocation/${id}/${date}?updateNext=${updateNext}`,
    alloc
  );
  return data;
};

export const updateGasMeter = async (id: string, gasMeter: any) => {
  const { data } = await api.patch(`gas-meters/${id}`, gasMeter);
  return data;
};

export const postTanks = async (gasMeter: any) => {
  const { data } = await api.post('measuring-tanks', gasMeter);
  return data;
};

export const updateOilwell = async (id: string, data: any, date: string) => {
  const { updateNext, ...oilwell } = data;
  const { data: responseData } = await api.patch(
    `${path}/${id}/${date}`,
    oilwell,
    {
      params: {
        updateNext: !!updateNext
      }
    }
  );
  return responseData;
};

export const updateChroma = async (id: string, chroma: any) => {
  const { data } = await api.patch(`chromatography-analysis/${id}`, chroma);
  return data;
};

export const updateManyChroma = async (
  chroma: any[],
  applicationDate: string
) => {
  const data = await Promise.all(
    chroma.map(chroma =>
      api.patch(
        `chromatography-analysis/application/${chroma.id}/${applicationDate}`,
        chroma
      )
    )
  );

  return data;
};

export const updateOilField = async (id: string, oilField: any) => {
  const { data } = await api.patch(`oil-fields/${id}`, oilField);
  return data;
};

export const updateSatellite = async (id: string, chroma: any) => {
  const { data } = await api.patch(`satellite/${id}`, chroma);
  return data;
};

export const updateTank = async (id: string, tank: any) => {
  const { data } = await api.patch(`measuring-tanks/${id}`, tank);
  return data;
};

export const deleteOilwell = async (id: string) => {
  const { data } = await api.delete(`${path}/${id}`);
  return data;
};

export const deleteChroma = async (id: string) => {
  const { data } = await api.delete(`chromatography-analysis/${id}`);
  return data;
};

export const deleteSatellite = async (id: string) => {
  const { data } = await api.delete(`satellites/${id}`);
  return data;
};

export const deleteOilField = async (id: string) => {
  const { data } = await api.delete(`oil-fields/${id}`);
  return data;
};

export const deleteGasMeter = async (id: string) => {
  const { data } = await api.delete(`gas-meters/${id}`);
  return data;
};

export const deleteZone = async (id: string) => {
  const { data } = await api.delete(`zones/${id}`);
  return data;
};

export const deleteOilwellZoneAllocation = async (id: string) => {
  const { data } = await api.delete(`oilwell-zone-allocation/${id}`);
  return data;
};

export const deleteTank = async (id: string) => {
  const { data } = await api.delete(`measuring-tanks/${id}`);
  return data;
};

export const deleteMeasuringTanks = async (id: number) => {
  const { data } = await api.delete(`measuring-tanks/${id}`);
  return data;
};

export const deleteGasMeters = async (id: number) => {
  const { data } = await api.delete(`gas-meters/${id}`);
  return data;
};

export const getOilwellSatellites = async () => {
  const { data } = await api.get(`satellites`);
  return data;
};

export const getOilwellStatus = async () => {
  const { data } = await api.get(`oil-well-status`);
  return data;
};

export const getOilwellNameAnp = async () => {
  const { data } = await api.get(`oil-wells`);
  return data;
};

export const getOilwellField = async () => {
  const { data } = await api.get(`oil-fields`);
  return data;
};

export const getLocation = async () => {
  const { data } = await api.get(`locations`);
  return data;
};

export const getConjugateLine = async () => {
  const { data } = await api.get(`conjugate-lines`);
  return data;
};

export const getCromaFormula = async () => {
  const { data } = await api.get(`chromatography-formula`);
  return data;
};

export const getGasMeters = async () => {
  const { data } = await api.get(`gas-meters`);
  return data;
};

export const getMeasuringTanks = async () => {
  const { data } = await api.get(`measuring-tanks`);
  return data;
};
